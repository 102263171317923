import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MessageFormComponent} from './message-form.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    MessageFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild()
  ],
  exports: [
    MessageFormComponent
  ]
})
export class MessageFormModule {

}
