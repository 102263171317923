import {ChangeDetectionStrategy, Component, Input, Output, EventEmitter, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'app-message-form',
  templateUrl: './message-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageFormComponent implements OnInit, OnDestroy {
  @ViewChild('messageForm') messageForm: NgForm;
  @Input('message') message: any;
  @Output() onFormChanged: EventEmitter<any> = new EventEmitter();

  priorityString = 'false';
  priority: number;

  private _formSubscription: Subscription;

  ngOnInit(): void {
    this._formSubscription = this.messageForm.valueChanges.subscribe(changes => this._validateForm());
    if (this.message) {
      this.priority = this.message.priority;
    }
  }

  onSwitchToggle(value: boolean): void {
    this.priorityString = value.toString();

    if (value) {
      this.message.priority = 1;
    } else {
      this.message.priority = 0;
    }
  }

  ngOnDestroy(): void {
    this._formSubscription.unsubscribe();
  }

  private _validateForm(): void {
    this.onFormChanged.emit({
      valid: this.messageForm.valid,
      message: this.message
    });
  }
}
