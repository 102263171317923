import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SegmentedBarComponent} from './segmented-bar.component';

@NgModule({
  declarations: [
    SegmentedBarComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SegmentedBarComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SegmentedBarModule {}
