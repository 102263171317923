import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export interface SegmentedBarOption {
  key: string;
  value: any;
}

@Component({
  moduleId: module.id,
  selector: 'app-segmented-bar',
  templateUrl: './segmented-bar.component.html'
})
export class SegmentedBarComponent implements OnInit {
  @Input() options: SegmentedBarOption[];
  @Input() selectedOption: SegmentedBarOption;
  @Output() onOptionSelected: EventEmitter<string> = new EventEmitter();

  ngOnInit(): void {
    if (this.options) {
      this.selectOption(0);
    }
  }

  selectOption(index: number): void {
    this.selectedOption = this.options[index];
    this.onOptionSelected.emit(this.selectedOption.key);
  }
}
