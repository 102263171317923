import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {SearchBoxComponent} from './search-box.component';

@NgModule({
  declarations: [
    SearchBoxComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild()
  ],
  exports: [
    SearchBoxComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SearchBoxModule {

}
