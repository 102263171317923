import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output,
  ViewChild
} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'app-entry-form',
  templateUrl: './entry-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntryFormComponent implements OnInit, OnDestroy {
  @ViewChild('entryForm') entryForm: NgForm;

  @Input() entry: any;
  @Output() onFormChanged: EventEmitter<any> = new EventEmitter();

  newImages: File[] = [];
  deleteImages: string[] = [];
  showInfo: boolean;

  private _formSubscription: Subscription;

  constructor(private _cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._formSubscription = this.entryForm.valueChanges
      .pipe(map(_ => this._validateForm()))
      .subscribe();
  }

  onImagesChanged(imageData: { newImages: File[], deleteIds: string[] }): void {
    this.newImages = imageData.newImages;
    this.deleteImages = imageData.deleteIds;
    this._validateForm();
  }

  showInfoMessage(): void {
    this.showInfo = !this.showInfo;
    setTimeout(() => {
      this.showInfo = !this.showInfo;
      this._cdRef.detectChanges();
    }, 10000);
  }

  ngOnDestroy(): void {
    if (this._formSubscription) {
      this._formSubscription.unsubscribe();
    }
  }

  private _validateForm(): void {
    this.onFormChanged.emit({
      valid: this.entry.text || this.newImages.length > 0,
      entry: this.entry,
      deleteIds: this.deleteImages,
      newImages: this.newImages
    });
  }

}
