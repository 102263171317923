import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-comment-form',
  templateUrl: './comment-form.component.html'
})
export class CommentFormComponent {
  @Output() onPublish: EventEmitter<any> = new EventEmitter();

  loggedInUser: any;
  comment: string = null;
  isValid = false;

  constructor() {}

  onTextChange(value) {
    this.comment = value;
    this.isValid = (this.comment.length > 0);
  }

  publish() {
    this.onPublish.emit(this.comment);
    this.comment = null;
    this.isValid = false;
  }

}
