import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {EntryFormComponent} from './entry-form.component';
import {TranslateModule} from '@ngx-translate/core';
import {AddImagesModule} from '../../shared/add-images/add-images.module';

@NgModule({
  declarations: [
    EntryFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    AddImagesModule
  ],
  exports: [
    EntryFormComponent
  ]
})
export class EntryFormModule {

}
