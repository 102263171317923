import {Component, Input} from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-page-header',
  template: `
    <div class="page-header">
      <h2 class="page-header__heading">{{ heading }}</h2>
      <div class="page-header__sub-heading">{{ subheading }}</div>
    </div>
  `
})

export class PageHeaderComponent {
  @Input() heading;
  @Input() subheading;
}
