import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  moduleId: module.id,
  selector: 'app-dialog-window',
  templateUrl: './dialog-window.component.html'
})

export class DialogWindowComponent implements OnChanges {
  @ViewChild('dialogWindow') dialogWindow: ElementRef;

  @Input() dialogThread: any[];
  @Input() loggedInUser: any;
  @Input() newMessage: any;
  @Input() isMobile: boolean;

  @Output() message: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _router: Router) {
  }

  ngOnChanges(): void {
    if (this.dialogThread === null) {
      this._router.navigate(['message'], {queryParams: {tab: 'dialogs'}});
    }
    this._scrollToBottom();
  }

  onImageError(event, image) {
    if (image['original']) {
      if (event.srcElement.src === image['original']) {
        event.srcElement.src = 'assets/imgUnavailable.png';
      } else {
        event.srcElement.src = image['original'];
      }
    }
  }

  send(): void {
    this.message.emit(this.newMessage);
    this.newMessage = '';
  }

  private _scrollToBottom() {
    if (this.dialogWindow) {
      setTimeout(_ => {
        try {
          this.dialogWindow.nativeElement.scrollTop = this.dialogWindow.nativeElement.scrollHeight;
        } catch (err) {}
      }, 0);
    }
  }
}

